import * as React from 'react';
import { HeroBanner } from '../Components/Common/HeroBanner';
import SupplierSlider from '../Components/Common/SupplierSlider';
import ProductsGrid from '../Components/Common/ProductsGrid';
import ServicesGrid from '../Components/Common/ServicesGrid'

const SolutionsPage: React.FC = () => {
	return (
		<>
			<HeroBanner pageSlug="solutions" />

			<ServicesGrid title="Services"/>

			<SupplierSlider />

			<ProductsGrid title="Products"/>
		</>
	)
};


export default SolutionsPage;
