import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby';
import ReactHtmlParser from 'react-html-parser';
import styled from 'styled-components';

import { GlobalContext } from '@/src/contexts/GlobalContext';

// Components
import { BrandedTitle } from './BrandedTitle';
import { ContentContainer } from './ContentContainer';
import { Collapsible } from '../Common/Collapsible';
import { DownloadCta } from './CTAs';
import { Buttons } from './Styles/Interactions';


interface IProductGridProps {
    title?: string;
}

const StyledProductsGrid = styled.section`
    display:flex;
    flex-direction: column;
    background: var(--almostWhite) 0% 0% no-repeat padding-box;
    padding: 64px 0 ;

    h2 {
        margin-bottom: 8px;
    }

    p.centered {
        max-width:80%;
        margin: 0 auto 48px;
        font-weight: 300;

        @media (min-width: ${props => props.theme.screensizes.large}) {
            max-width:60%;
        }
    }

    .content-wrapper {
        max-width: 1032px;
        margin:0 auto;
    }

    .inner-wrapper {
        display:flex;
        flex-wrap:wrap;

        @media (min-width: ${props => props.theme.screensizes.medium}) {
            justify-content: space-between;
        }
    }
`;

const StyledSingleProduct = styled.div`
    margin-bottom: 48px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        flex:0 0 calc(50% - 32px);
    }
    .img-wrapper {
        overflow: hidden;
    }

    .collapsible {
        &.collapsible--open {
            .title-container-alt {
                border-bottom: none;
            }

            > :last-child {
                border-bottom: 1px solid ${props => props.theme.colors.casperGrey};
            }
        }

        .title-container-alt {
            background-color: transparent;
            padding: 16px 0;
            margin-top: 16px;
            border-top: 1px solid ${props => props.theme.colors.casperGrey};

            .title {
                margin-bottom: 0px;
                font-size: 24px;
                font-weight: 400;
            }
        }

        .content-inner {
            padding: 0;

            p {
                font-weight: 300;
            }

            a:first-of-type {
                font-size: 16px;
                font-weight: 400;
            }
        }
    }

`;

const ButtonsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    @media (min-width: ${props => props.theme.screensizes.medium}) {
        flex-direction: row;
    }

    a {
        font-weight: 600 !important;
        font-size: 18px !important;
        max-width: initial !important;
    }
    @media (min-width: ${props => props.theme.screensizes.medium}) {

        > :last-child {
            margin-left: 24px;
        }

    }
`

const ProductsGrid: React.FC<IProductGridProps> = (props) => {
    const { products } = useStaticQuery<GatsbyTypes.Query>(graphql`
        query  {
            products: wordpress {
                products(first:100) {
                    nodes {
                        title
                        uri
                        content
                        featuredImage {
                            node {
                                sourceUrl(size: LARGE)
                            }
                        }
                        excerpt(format: RAW)
                        singleProductFields {
                            relatedCaseStudy {
                                ... on WpGql_CaseStudy {
                                    uri
                                }
                            }
                            datasheetupload {
                                mediaItemUrl
                            }
                            actions {
                                link
                                text
                                type
                            }
                        }
                    }
                }
            }
        }
    `);

    const productArr = products?.products?.nodes;

    const { location } = React.useContext(GlobalContext);   

    return (
        <StyledProductsGrid {...props?.title && { id: props.title.toLowerCase() }} >
            <ContentContainer>
                { props?.title && (
                    <BrandedTitle>{ props.title }</BrandedTitle>
                )}
                <p className="centered content-intro">What ever your businesses requirements, we have the right equipment to ensure that your team are not put in any un-necessary risk.</p>
                <div className="inner-wrapper">
                    {
                        productArr.map((product, index) => {
                            const imgUrl = product?.featuredImage?.node?.sourceUrl;
                            const shouldOpen = (location?.hash?.replace('#', '') === product.uri.replace('/product/', '')) || false

                            return (
                                <StyledSingleProduct
                                    className="product"
                                    key={`product-${index}`}
                                    { ...product?.uri && { id: product.uri.replace('/product/', '') }}
                                >
                                    <div className="img-wrapper">
                                        <img src={imgUrl} alt={product.title} className="product-product__image" />
                                    </div>
                                    <Collapsible
                                        title={product.title}
                                        alt={ true }
                                        altArrow={ true }
                                        open={ shouldOpen }
                                        openAlt={ true }
                                    >
                                        <div className="content-inner">
                                            <div className="inner-content">
                                                { ReactHtmlParser(product.content) }

                                                { product?.singleProductFields?.datasheetupload && (
                                                    <DownloadCta
                                                        className="download-cta"
                                                        title="Download PDF Data Sheets"
                                                        download={ true }
                                                        href={ product.singleProductFields.datasheetupload.mediaItemUrl }
                                                    />
                                                )}
                                                { (product?.singleProductFields?.actions || product?.singleProductFields?.relatedCaseStudy)  && (
                                                    <ButtonsWrapper>
                                                        {
                                                            product?.singleProductFields?.actions.map((action, index) => {
                                                                const Button = Buttons[action.type as string]
                                                                return <Button key={ index }  text={ action.text } href={ action.link } />
                                                            })
                                                        }
                                                        {
                                                            product?.singleProductFields?.relatedCaseStudy.map((related, index) => {
                                                                return <Buttons.Secondary key={ index } text="Read Case Study" href={ related.uri } target="_self"/>
                                                            })
                                                        }
                                                    </ButtonsWrapper>
                                                )}
                                            </div>
                                        </div>
                                    </Collapsible>
                                </StyledSingleProduct>
                            )
                        })
                    }
                </div>
            </ContentContainer>
 

        </StyledProductsGrid>

    )
}

export default ProductsGrid;