import * as React from 'react';
import styled from 'styled-components'
import ShareIcon from '../../images/icons/share-icon.svg'
import DownloadIcon from '../../images/icons/download-icon.svg'
import { Download } from '@styled-icons/material-twotone';

interface ICtaProps {
    title: string;
    href?: string;
    download?:any
    className?:string
}

const StyledCta = styled.a`
    font-size: 18px;
    line-height: 20px;
    font-weight: 300;
    display: flex;
    align-items: center;
    color: var(--nearlyNavy);
    font-family: var(--din-font);
    text-transform: capitalize;
    svg {
        margin-right: 8px;
        min-width: 32px;
    }
`;

export const DownloadCta: React.FC<ICtaProps> = (props) => {
    return (
        <StyledCta title={props.title} { ...props?.download && { download: props.download }} { ...props?.href && { href: props.href }}>
            <DownloadIcon />
            {props.title}
        </StyledCta>
    )
}

export const ShareCta: React.FC<ICtaProps> = (props) => {
    return (
        <StyledCta title={props.title} { ...props?.download && { download: props.download }} { ...props?.href && { href: props.href }}>
            <ShareIcon />
            {props.title}
        </StyledCta>
    )
}

